<template>
  <div class="vg_wrapper">
    <add-header
        @submit="submit('crftForm')"
        @cancel="cancel">
    </add-header>
    <el-form ref="crftForm" :model="crftForm" :rules="rules" label-width="120px" size="mini">
      <el-row :gutter="24">
        <el-col :md="8">
          <el-form-item label="工艺编号" prop="crft_no">
            <el-input v-model="crftForm.crft_no" maxlength="10" @input ="crftForm.crft_no = helper.keepEngNum2(crftForm.crft_no)" show-word-limit placeholder="请填写工艺编号（仅支持数字和英文）"></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="工艺描述" prop="crft_name">
            <el-input v-model="crftForm.crft_name" type="textarea" rows="1" maxlength="30" show-word-limit placeholder="请填写工艺名称"></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="工艺类型" prop="crft_type">
            <el-select v-model="crftForm.crft_type" placeholder="请选择工艺类型" clearable @visible-change="getCrftType($event)">
              <el-option
                  v-for="item in crftTypeGroupOpt"
                  :key="item.id"
                  :label="item.param1"
                  :value="item.param1">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-divider></el-divider>
      <el-row class="vg_mt_16">
        <el-col>
          <inputUser :isCookie="true" ref="userData"></inputUser>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import {get, post} from "@api/request";
import {crftAPI} from "@api/modules/crft";
import inputUser from "@/views/component/inputUser";
import addHeader from "@/views/component/addHeader";
import options from "@/views/component/common/options";
import {optnAPI} from "@api/modules/optn";
export default {
  name: "CrftAddMain",
  components: {
    inputUser,
    addHeader
  },
  data() {
    return {
      crftForm: {
        crft_no: '',
        crft_name: '',
        crft_type: '',
        key: 0,
        status: 0,
      },
      crftTypeGroupOpt:[],
      rules:{
        crft_no:[
          { required: true, trigger: 'blur', message: ' ' },
        ],
        crft_name:[
          { required: true, trigger: 'blur', message: ' ' },
        ],
        crft_type:[
          { required: true, trigger: 'change', message: ' ' },
        ],
      }
    }
  },
  created() {
    this.crftTypeGroupOpt = options.crftOpts
  },
  methods: {
    // 获取工艺类型
    getCrftType(flag){
      // if(flag === true && this.crftTypeGroupOpt.length === 0){
      //   this.crftTypeGroupOpt = options.crftOpts
      // }
      get(optnAPI.getAllContent,{perm_id : 10002})
          .then(res=>{
            if(res.data.code === 0) {
              this.crftTypeGroupOpt = res.data.data
            }
          })
    },
    //提交表单
    submit(formName){
      this.$confirm('确认提交?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.saveInfo()
          } else {
            console.log('error submit!');
            return false;
          }
        });
      })
    },
    // 保存
    saveInfo() {
      const staffForm =  this.$refs.userData.getData()
      this.crftForm = Object.assign(this.crftForm,staffForm)
      post(crftAPI.addCrft,this.crftForm)
          .then(res => {
            if(res.data.code === 0){
              this.$message({
                type:'success',
                message:'保存成功'
              })
              const permId = this.$route.query.perm_id
              this.jump(`/crft_edit?perm_id=${permId}&form_id=${res.data.data.form_id}`)
              this.resetForm('crftForm')
            }else if(res.data.code === 7){
              this.$message({
                type:'error',
                message:'工艺编号重复'
              })
            } else {
              let mg = res.data.msg;
              let tp = 'error';
              this.$message({message: mg, type: tp});
            }
          })
    },
    //取消
    cancel(){
      this.$confirm('确定取消新增?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      }).then(() => {
        this.resetForm('crftForm')
        this.$router.go(-1)
        this.$message({
          type: 'info',
          message: '已取消新增!'
        });
      })
    },
    //表单重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  }
}
</script>

<style scoped>

</style>